import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IUser } from '../interfaces/IUser';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { observable, Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import * as rutHelpers from 'rut-helpers';
import { IRegistryUser } from '../interfaces/IRegistryUser';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private host = environment.host;
  private uid:string = "";
  private token:string = "";
  private user_rol:string = "";
  private nombre:string = "";
  private genero:string = "";
  public userLogin:any;
  private apiPath = {
    pathAuth : '/api/auth',
    pathAuthAWS : '/api',
  }
 
  constructor(private http:HttpClient,
              private router:Router) { }

    Registry = (newUser:IRegistryUser) => { 
 
        return this.http.post(`${this.host}/api/registry`,newUser)
        .pipe(
          map((resp:any) => {
            if(resp.status == true){
              this.userLogin = resp;
              return resp;
            }else{
              return resp;
            }
            
          },
          catchError(err => {
              console.log(err);
              return of(err.error);
            })
          ));
 
    }

    findUser = (rut:string) => { 
      return this.http.post(`${this.host}/api/findUser`,{rut:rut})
      .pipe(
        map((resp:any) => {
            return resp;
        },
        catchError(err => {
            console.log(err);
            return of(err.error);
          })
        ));
  }
 
aceptaTerminos = (uid:string) => { 
  return this.http.post(`${this.host}/api/aceptaTerminos/${uid}`,{"msg":"aceptaTerminos","status":true})
  .pipe(
    map((resp:any) => {
        return resp;
    },
    catchError(err => {
        console.log(err);
        return of(err.error);
      })
    ));
}

  //   Login = (user:IUser) => { 
 
  //     let username : string = rutHelpers.rutClean(user.rut.trim()); //user.rut.trim().replace("-","").replace(".","");
  //     let password : string = user.password;
 
  //     let authorizationData = 'Basic ' + btoa(username + ':' + password);
        
  //    if(this.userLogin){
  //      return this.userLogin;
  //    }else{
  //      return this.http.post(`${this.host}${this.apiPath.pathAuthAWS}/login`,{},
  //      {headers: new HttpHeaders()
  //      .set('Content-Type','application/json; charset=utf-8')
  //      .set('Authorization', authorizationData)})
  //      .pipe(
  //        map((resp:any) => {
  //          if(resp.status == true){
  //            this.userLogin = resp;
  //            return resp;
  //          }else{
  //            return resp;
  //          }
  //        },
  //        catchError(err => {
  //            console.log(err);
  //            return of(err.error);
  //          })
  //        ));
  //    }
  //  }

  Login(user: IUser): Promise<any> {
    let username: string = rutHelpers.rutClean(user.rut.trim());
    let password: string = user.password;

    let authorizationData = 'Basic ' + btoa(username + ':' + password);

    // if (this.userLogin) {
    //   return Promise.resolve(this.userLogin);
    // } else {
      return this.http.post(`${this.host}${this.apiPath.pathAuthAWS}/login`, {},
        {
          headers: new HttpHeaders()
            .set('Content-Type', 'application/json; charset=utf-8')
            .set('Authorization', authorizationData)
        }
      ).pipe(
        map((resp: any) => {
          if (resp.status == true) {
            this.userLogin = resp;
            return resp;
          } else {
            return resp;
          }
        }),
        catchError(err => {
          console.log(err);
          return of(err.error);
        })
      ).toPromise();
    // }
  }

  LoginAux = (user:IUser) => { 
 
    // let username : string = '18.341.439-9';
    // let password : string = '64136000xX-';

    let authorizationData = 'Basic ' + btoa(user.rut + ':' + user.password);

    console.log("autenticacion desde login");

    const headers = new HttpHeaders(
      {
      'Content-Type': 'application/json; charset=utf-8',
      'Authorization': authorizationData
      }
    );
  
    if(this.userLogin){
      return this.userLogin;
    }else{
      return this.http.post(`${this.host}${this.apiPath.pathAuth}/Login`,user,{headers:headers})
      .pipe(
        map((resp:any) => {
          if(resp.status == true){
            this.userLogin = resp;
            return resp;
          }else{
            return resp;
          }
          
        },
        catchError(err => {
            console.log(err);
            return of(err.error);
          })
        ));
    }
  }

  handlerError(error: HttpErrorResponse){
    return throwError('Oops !!!, ha ocurrido un error, la alerta ya ha sido enviada al departamento de informática.');
  }

  saveAccess = (user:IUser) =>  {
    this.uid = user.uid || '';
    this.user_rol = user.user_rol || '';
    this.nombre = user.nombre || '';
    this.token = user.token || '';
    this.genero = user.genero || '3';
    localStorage.setItem('uid', this.uid );
    localStorage.setItem('user_rol',user.user_rol);
    localStorage.setItem('nombre',user.nombre);
    localStorage.setItem('genero',user.genero);
    localStorage.setItem('token',user.token);
  }

  readAccess = () => {
    if( localStorage.getItem('uid') ){
      this.uid = localStorage.getItem('uid');
    }else{
      this.uid = '';
    }

    if( localStorage.getItem('token') ){
      this.token = localStorage.getItem('token');
    }else{
      this.token = '';
    }

    if( localStorage.getItem('user_rol') ){
      this.user_rol = localStorage.getItem('user_rol');
    }else{
      this.user_rol = '';
    }

    if( localStorage.getItem('nombre') ){
      this.nombre = localStorage.getItem('nombre');
    }else{
      this.nombre = '';
    }

    if( localStorage.getItem('genero') ){
      this.genero = localStorage.getItem('genero');
    }else{
      this.genero = '';
    }
 
     let _user:IUser = {
       uid     : this.uid === '' ? '' : this.uid,
       token   : this.token === '' ? '' : this.token,
       rut     : '', 
       email   : '',
       password: '',
       user_rol: this.user_rol == undefined ? '' : this.user_rol,
       nombre  : this.nombre === undefined ? '' : this.nombre,
       genero  : this.genero === undefined ? '' : this.genero,
     };

    return _user;
  }

  logOut = () => {
    this.userLogin = null;
    localStorage.clear();
    this.router.navigateByUrl('/auth/signin');
  }

  clearStorage = () => {
    this.userLogin = null;
    localStorage.clear();
  }

  RolByUser = () =>{
    if(localStorage.getItem('user_rol') ){
      return  localStorage.getItem('user_rol');
    }else{
      return 'NOROL';
    }

  }
 
  users() {
    return this.http.get('https://jsonplaceholder.typicode.com/users').
    pipe(
      map((resp:any) => {
        return resp;
      },
      catchError(err => {
          console.log(err);
          return of(err.error);
        })
      ));
  }

}
 